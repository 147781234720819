import JSON_ROUTE_DATA from '$generated/routes.json';
import { pathToRegexp } from 'path-to-regexp';

export type TSlugifiedRoute = [string, RegExp];
export type TTemplateRouteDictionary = { default: Set<string>; slugified: TSlugifiedRoute[] };
export type TRoutesDictionary = Record<string, TTemplateRouteDictionary>;
export type TAvailableTemplates = keyof typeof JSON_ROUTE_DATA;

const ROUTES: TRoutesDictionary = {};
Object.entries(JSON_ROUTE_DATA).forEach(([templateName, templateRoutes]) => {
    ROUTES[templateName] = {
        default: new Set(templateRoutes.default),
        slugified: templateRoutes.slugified.map((route) => [route, pathToRegexp(route)])
    };
});

export default ROUTES;
