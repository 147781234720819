export const AVAILABLE_LANGUAGES = {
    'en-GB': {
        code: 'en-GB',
        name: 'English (United Kingdom)'
    },
    'sv-SE': {
        code: 'sv-SE',
        name: 'Svenska'
    },
    'es-ES': {
        code: 'es-ES',
        name: 'Spanish'
    }
};
