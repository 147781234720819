import { AVAILABLE_LANGUAGES } from '$lib/services/locale/constants';
import type { TMarket } from './types';

export const AVAILABLE_MARKETS: Record<string, TMarket> = {
    en: {
        slug: 'en',
        losServiceSlug: 'en',
        targetGeoLocations: [],
        isGeoFenced: false,
        isGeoJailed: false,
        supportedLanguages: [AVAILABLE_LANGUAGES['en-GB'], AVAILABLE_LANGUAGES['sv-SE']],
        isSportsEnabled: false,
        hasRgHeader: false,
        locale: 'en',
        jurisdiction: 'MGA'
    },
    ie: {
        slug: 'ie',
        losServiceSlug: 'ie',
        targetGeoLocations: [],
        isGeoFenced: false,
        isGeoJailed: false,
        supportedLanguages: [AVAILABLE_LANGUAGES['en-GB']],
        isSportsEnabled: true,
        hasRgHeader: false,
        locale: 'en-IE',
        jurisdiction: 'MGA'
    },
    'en-gb': {
        slug: 'en-gb',
        losServiceSlug: 'engb',
        targetGeoLocations: [
            {
                country: 'GB',
                region: null
            }
        ],
        isGeoFenced: false,
        isGeoJailed: false,
        supportedLanguages: [AVAILABLE_LANGUAGES['en-GB']],
        isSportsEnabled: true,
        hasRgHeader: false,
        locale: 'en-GB',
        jurisdiction: 'UKGC'
    },
    sv: {
        slug: 'sv',
        losServiceSlug: 'sv',
        targetGeoLocations: [
            {
                country: 'SE',
                region: null
            }
        ],
        isGeoFenced: false,
        isGeoJailed: true,
        supportedLanguages: [AVAILABLE_LANGUAGES['sv-SE'], AVAILABLE_LANGUAGES['en-GB']],
        isSportsEnabled: true,
        hasRgHeader: true,
        locale: 'sv-SE',
        jurisdiction: 'SGA'
    },
    rog: {
        slug: 'rog',
        losServiceSlug: 'rog',
        targetGeoLocations: [],
        isGeoFenced: false,
        isGeoJailed: true,
        supportedLanguages: [AVAILABLE_LANGUAGES['en-GB']],
        isSportsEnabled: false,
        hasRgHeader: false,
        locale: 'en-ROG',
        jurisdiction: 'MGA'
    },
    row: {
        slug: 'row',
        losServiceSlug: 'row',
        targetGeoLocations: [],
        isGeoFenced: false,
        isGeoJailed: true,
        supportedLanguages: [AVAILABLE_LANGUAGES['en-GB']],
        isSportsEnabled: false,
        hasRgHeader: false,
        locale: 'en-ROW',
        jurisdiction: 'MGA'
    },
    'en-ca': {
        slug: 'en-ca',
        losServiceSlug: 'enca',
        targetGeoLocations: [],
        isGeoFenced: false,
        isGeoJailed: true,
        supportedLanguages: [AVAILABLE_LANGUAGES['en-GB']],
        isSportsEnabled: true,
        hasRgHeader: false,
        locale: 'en-CA',
        jurisdiction: 'MGA'
    },
    on: {
        slug: 'on',
        losServiceSlug: 'on',
        targetGeoLocations: [],
        isGeoFenced: false,
        isGeoJailed: true,
        supportedLanguages: [AVAILABLE_LANGUAGES['en-GB']],
        isSportsEnabled: true,
        hasRgHeader: true,
        locale: 'en-CA',
        jurisdiction: 'MGA'
    },
    'en-nz': {
        slug: 'en-nz',
        losServiceSlug: 'ennz',
        targetGeoLocations: [],
        isGeoFenced: false,
        isGeoJailed: true,
        supportedLanguages: [AVAILABLE_LANGUAGES['en-GB']],
        isSportsEnabled: true,
        hasRgHeader: false,
        locale: 'en-NZ',
        jurisdiction: 'MGA'
    },
    'en-in': {
        slug: 'en-in',
        losServiceSlug: 'enin',
        targetGeoLocations: [],
        isGeoFenced: false,
        isGeoJailed: true,
        supportedLanguages: [AVAILABLE_LANGUAGES['en-GB']],
        isSportsEnabled: false,
        hasRgHeader: false,
        locale: 'en-IN',
        jurisdiction: 'MGA'
    },
    fi: {
        slug: 'fi',
        losServiceSlug: 'fi',
        targetGeoLocations: [],
        isGeoFenced: false,
        isGeoJailed: true,
        supportedLanguages: [AVAILABLE_LANGUAGES['en-GB']],
        isSportsEnabled: false,
        hasRgHeader: false,
        locale: 'fi-FI',
        jurisdiction: 'MGA'
    },
    no: {
        slug: 'no',
        losServiceSlug: 'no',
        targetGeoLocations: [],
        isGeoFenced: false,
        isGeoJailed: true,
        supportedLanguages: [AVAILABLE_LANGUAGES['en-GB']],
        isSportsEnabled: true,
        hasRgHeader: false,
        locale: 'no-NO',
        jurisdiction: 'MGA'
    },
    es: {
        slug: 'es',
        losServiceSlug: 'es',
        targetGeoLocations: [],
        isGeoFenced: false,
        isGeoJailed: true,
        supportedLanguages: [AVAILABLE_LANGUAGES['es-ES']],
        isSportsEnabled: true,
        hasRgHeader: true,
        locale: 'es-ES',
        jurisdiction: 'DGOJ'
    }
};
