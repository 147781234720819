export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16')
];

export const server_loads = [0,2,3];

export const dictionary = {
		"/[[market=isMarket]]/iddqd": [12,[6]],
		"/[[market=isMarket]]/iddqd/error-pages": [13,[6]],
		"/[[market=isMarket]]/iddqd/error-pages/maintainance": [15,[6]],
		"/[[market=isMarket]]/iddqd/error-pages/[code]": [~14,[6]],
		"/[[market=isMarket]]/iddqd/tools": [~16,[6]],
		"/(content)/(no-hub)/[[market=isValidMarket]]/more": [~8,[2]],
		"/(noshell)/[[market=isMarket]]/register": [~11,[5]],
		"/(content)/(hub)/[...pathname=isValidHubContentUrl]": [~7,[2,3]],
		"/(content)/(sports-hub)/[...pathname=isValidSportsHubContentUrl]": [~9,[2,4]],
		"/(noshell)/[...pathname=isValidNoShellUrl]": [~10,[5]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';